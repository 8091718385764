import type { LoanRoleDto, PackageInfoSharingResponseDto, Role } from "src/backend";
import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";
import { BasicLoanRoleDtoExtended } from "src/services/loanApi";

import { getRoleForRoleGroup } from "../get-role-for-role-group";

export const mapSharedInfoToAssignListItem = (args: { sharedInfo: PackageInfoSharingResponseDto[], loanRoles: Pick<BasicLoanRoleDtoExtended, 'role' | 'user'>[], loggedInUserId: string }): TeamMemberSelectListAssignedListItem[] => {
    const loanRoleByUserIdKey = args.loanRoles.reduce((acc, loanRole) => {
        acc[loanRole.user.id] = loanRole.role;
        return acc;
    }, {} as Record<string, Role>);

    return args.sharedInfo
        .filter(sharedInfo => !sharedInfo.permissions.includes('LINK'))
        .map((sharedInfo: PackageInfoSharingResponseDto) => ({
            user: {
                ...sharedInfo.sharedWithUser,
                isLoggedUser: sharedInfo.sharedWithUser.id === args.loggedInUserId
            },
            role: loanRoleByUserIdKey[sharedInfo.sharedWithUser.id] ?? getRoleForRoleGroup(sharedInfo.sharedWithUser.loggedRoleGroup),
            type: true,
        }))
};