import { getFileNameExtension, getFileType } from 'components/dist/molecules/FileIcon';
import { useRouter } from 'next/router';
import { useMemo, useState } from "react";
import { LoanOverviewNeedsListFiltersState } from "src/components/loans/tabs/overview/v2/loan-overview-needs-list/loan-overview-needs-list.types";
import { LoanReviewStatusPhaseCategoryMap } from "src/components/loans/tabs/overview/v2/loan-overview-ready-for-review/loan-overview-ready-for-review.hook";
import { QUERY_PARAM_PACKAGE_ASSIGNEE_IDS_FILTER, QUERY_PARAM_PACKAGE_GROUP_BY_FILTER, QUERY_PARAM_PACKAGE_STATUS_FILTER, QUERY_PARAM_PACKAGE_TASK_FILTER, QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER } from 'src/constants/form-element';
import { KeyStorage } from 'src/constants/key-storage';
import { Route } from 'src/constants/routing';
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { doesDateMatchModifiedFilter } from 'src/utils/filters/does-date-match-modified-filter';
import { getIconDocumentName } from "src/utils/form-element/get-icon-document-name";
import { isAnyPackageFilterOn } from 'src/utils/form-element/is-any-package-filter-on';
import { getChildrenIds } from 'src/utils/form-elements';

import { useKeyStorage } from '../use-key-storage';

interface UseElementsFilterProps {
    persistKey: 'PACKAGE' | 'NEEDS_LIST';
    displayHiddenFormElements: boolean;
    allElements: FormElementV2ResponseDtoExtended[];
    currentFolderFormElement: FormElementV2ResponseDtoExtended;
}

export const emptyPackageFilterState: LoanOverviewNeedsListFiltersState = {
    modified: undefined,
    type: [],
    status: [],
    entitiesIds: [],
    assigneesIds: [],
    phaseCategory: null,
    unAssigned: false,
    groupBy: null,
    sortBy: null,
    sortDirection: null,
    labels: []
}

export const useElementsFilter = (props: UseElementsFilterProps) => {
    const router = useRouter();
    const loanId = router.query.loanId as string;
    const initialPackageFilterState: LoanOverviewNeedsListFiltersState = {
        ...emptyPackageFilterState,
        groupBy: props.persistKey === 'NEEDS_LIST' ? 'ENTITY' : null
    }

    const {
        value: remoteFilters,
        setValue: saveRemoteFilters,
        isLoading: isLoadingRemoteFilters } = useKeyStorage<LoanOverviewNeedsListFiltersState>(`${KeyStorage.PackageFilterState}-${loanId}-${props.persistKey}`, initialPackageFilterState);

    const isTaskFilter = !!router.query[QUERY_PARAM_PACKAGE_TASK_FILTER] ||
        router.pathname === Route.BORROWER_PORTAL_LENDER_TASKS;

    const initialFilters: LoanOverviewNeedsListFiltersState = {
        modified: undefined,
        type: [],
        status: getInitialStatus(router.query[QUERY_PARAM_PACKAGE_STATUS_FILTER]),
        assigneesIds: getInitialAssignees(router.query[QUERY_PARAM_PACKAGE_ASSIGNEE_IDS_FILTER]),
        entitiesIds: [],
        phaseCategory: null,
        unAssigned: !!router.query[QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER],
        groupBy: getInitialGroupBy(router.query[QUERY_PARAM_PACKAGE_GROUP_BY_FILTER]),
        sortDirection: 'ASC',
        sortBy: 'PROJECT_NAME',
        labels: []
    }
    const [filterSelected, setFilterSelected] = useState<LoanOverviewNeedsListFiltersState>(initialFilters);

    const mergedFilters = {
        ...filterSelected,
        ...!isTaskFilter && remoteFilters
    }

    const onClearFilters = () => {
        setFilterSelected(initialPackageFilterState);
        saveRemoteFilters(initialPackageFilterState);
    }

    const onFilterSelectedChange = (args: LoanOverviewNeedsListFiltersState) => {
        setFilterSelected(args);
        saveRemoteFilters({
            ...args,
            // @ts-expect-error
            modified:
                args.modified ? {
                    label: args.modified?.label,
                    startDate: args.modified?.startDate instanceof Date ? args.modified?.startDate?.toISOString() : undefined,
                    endDate: args.modified?.endDate instanceof Date ? args.modified?.endDate?.toISOString() : undefined,
                } : undefined,
            groupBy: props.persistKey === 'NEEDS_LIST' && !args.groupBy ? 'ENTITY' : args.groupBy
        });
    };

    const isAFilterOn = isAnyPackageFilterOn(mergedFilters, props.persistKey);

    const filteredFormElements: FormElementV2ResponseDtoExtended[] = useMemo(() => {
        const elements = []
        if (props.currentFolderFormElement) {
            if (isAFilterOn) {
                const ids = getChildrenIds(props.currentFolderFormElement, props.allElements, true);
                elements.push(...props.allElements.filter((formElement) => ids.includes(formElement.id)));
            } else {
                elements.push(...props.allElements.filter((formElement) => formElement.parentId === props.currentFolderFormElement.id));
            }
        } else {
            elements.push(...props.allElements);
        }
        if (!elements) return [];
        return elements
            .filter((formElement) => (!formElement.hidden || props.displayHiddenFormElements || mergedFilters.phaseCategory !== null))
            .filter((formElement) =>
                // filter type
                doesElementAnswerMatchFilter(formElement, mergedFilters.type) &&
                // filter modified
                doesDateMatchModifiedFilter(formElement.lastModifiedDate, mergedFilters.modified) &&
                // filter assignees
                doesAssignOrUnAssignedMatchFilter(formElement, mergedFilters.assigneesIds, mergedFilters.unAssigned) &&
                // filter phase category
                doesVisibleAtStatusMatchFilter(formElement, mergedFilters.phaseCategory) &&
                // filter status
                doesElementStatusMatchFilter(formElement, mergedFilters.status, mergedFilters.groupBy) &&
                // filter entities
                doesElementSherpaEntityIdMatchFilter(formElement, mergedFilters.entitiesIds) &&
                // filter group by
                doesElementGroupByMatchFilter(formElement, mergedFilters.groupBy, mergedFilters.type) &&
                // filter by modifier label
                doesElementLabelMatchFilter(formElement, mergedFilters.labels, mergedFilters.groupBy)
            );

    }, [props.currentFolderFormElement, props.allElements, props.displayHiddenFormElements, isAFilterOn, mergedFilters.phaseCategory, mergedFilters.type, mergedFilters.modified, mergedFilters.assigneesIds, mergedFilters.unAssigned, mergedFilters.status, mergedFilters.groupBy, mergedFilters.entitiesIds, mergedFilters.labels]);

    return ({
        filteredFormElements,
        filterSelected: mergedFilters,
        isAFilterOn,
        onFilterSelectedChange,
        onClearFilters,
        isLoading: isLoadingRemoteFilters,
    }) as const;
};

const doesElementGroupByMatchFilter = (formElement: FormElementV2ResponseDtoExtended, groupBy: LoanOverviewNeedsListFiltersState['groupBy'], types: LoanOverviewNeedsListFiltersState['type']) => {
    // if group by is set and type is not folder 
    // do not return folder elements
    if (groupBy && formElement.storageType === "FOLDER" && !types.includes("Folder")) {
        return false;
    }
    return true;
}

const doesAssignOrUnAssignedMatchFilter = (formElement: FormElementV2ResponseDtoExtended, assigneesIds: LoanOverviewNeedsListFiltersState['assigneesIds'], unAssigned: LoanOverviewNeedsListFiltersState['unAssigned']) => {
    const assignMatch = doesAssignedUserMatchFilter(formElement, assigneesIds);
    const unAssignedMatch = doesUnAssignedMatchFilter(formElement, unAssigned);
    if (assigneesIds.length > 0 && unAssigned) {
        return assignMatch || unAssignedMatch
    } else if (assigneesIds.length > 0) {
        return assignMatch
    } else if (unAssigned) {
        return unAssignedMatch
    }
    return true;
}

const doesAssignedUserMatchFilter = (formElement: FormElementV2ResponseDtoExtended, assigneesIds: LoanOverviewNeedsListFiltersState['assigneesIds']) => {
    if (assigneesIds.length === 0) return true;
    if (formElement.storageType === 'FOLDER') return false
    const sharedWithUsersIds = formElement.sharedInfo?.map((sharedInfo) => sharedInfo.sharedWithUser.id) ?? [];
    return assigneesIds.some((assigneeId) => sharedWithUsersIds.includes(assigneeId));
}

const doesVisibleAtStatusMatchFilter = (formElement: FormElementV2ResponseDtoExtended, phaseCategory: LoanOverviewNeedsListFiltersState['phaseCategory']) => {
    if (phaseCategory === null) return true;
    if (!formElement.visibleAtStatus) return false;
    if (formElement.storageType === "FOLDER") return false;
    return LoanReviewStatusPhaseCategoryMap[formElement.visibleAtStatus].includes(phaseCategory);
}

const doesElementAnswerMatchFilter = (formElement: FormElementV2ResponseDtoExtended, type: LoanOverviewNeedsListFiltersState['type']) => {
    if (type.length === 0) return true;

    if (type.includes('Folder') && formElement.storageType === "FOLDER") {
        return true;
    }
    if (formElement.storageType === "FOLDER") return false;
    return type.includes(getFileType(getFileNameExtension(getIconDocumentName({
        documentName: formElement.answer?.document.name,
        modifiers: formElement.modifiers
    }))));
}

const doesElementSherpaEntityIdMatchFilter = (formElement: FormElementV2ResponseDtoExtended, entitiesIds: LoanOverviewNeedsListFiltersState['entitiesIds']) => {
    if (entitiesIds.length === 0) return true;

    if (formElement.storageType === "FOLDER") return false;

    return entitiesIds.includes(formElement.sherpaEntityId) ||
        (formElement.modifiers.some(modifier => entitiesIds.includes(modifier))) ||
        (entitiesIds.includes('UNASSIGNED') &&
            !formElement.sherpaEntityId &&
            !formElement.modifiers.some(modifier => modifier));
}

const doesElementStatusMatchFilter = (
    formElement: FormElementV2ResponseDtoExtended,
    status: LoanOverviewNeedsListFiltersState['status'],
    groupBy: LoanOverviewNeedsListFiltersState['groupBy']
) => {
    if (groupBy === 'TYPE' && !formElement.answer) {
        return false;
    }
    if (status.length === 0) return true;

    if (formElement.storageType === "FOLDER") return false;

    if (status.length === 0) {
        return true;
    }

    if (status.includes('OPEN_FILE_REQUEST') && !formElement.answer) {
        return true;
    }

    if (status.includes("OPEN") &&
        !formElement.answer &&
        !(formElement.modifiers.includes("FILLABLE_FORM") ||
            formElement.modifiers.includes("SYSTEM_FORM"))) {
        return false;
    }
    return status.includes(formElement.status);
}

const doesElementLabelMatchFilter = (
    formElement: FormElementV2ResponseDtoExtended,
    labels: LoanOverviewNeedsListFiltersState['labels'],
    groupBy: LoanOverviewNeedsListFiltersState['groupBy']
) => {
    if (groupBy === 'TYPE' && !formElement.answer) {
        return false;
    }
    if (labels.length === 0) return true;

    if (formElement.storageType === "FOLDER") return false;

    if (labels.length === 0) {
        return true;
    }


    return labels.some((label) => formElement.modifiers.includes(label));
}

const doesUnAssignedMatchFilter = (formElement: FormElementV2ResponseDtoExtended, unAssigned: LoanOverviewNeedsListFiltersState['unAssigned']) => {
    if (formElement.storageType === "FOLDER") return false;
    if (!unAssigned) return true;
    return formElement.sharedInfo.length === 0;
}

const getInitialGroupBy = (query: string | string[]): LoanOverviewNeedsListFiltersState['groupBy'] => {
    if (['ENTITY', 'ASSIGNEE'].includes(query as string)) {
        return query as LoanOverviewNeedsListFiltersState['groupBy'];
    }

    return null;
};

const getInitialStatus = (query: string | string[]): LoanOverviewNeedsListFiltersState['status'] => {
    const statusList: LoanOverviewNeedsListFiltersState['status'] = []
    if (Array.isArray(query) && query.length > 0) {
        query.forEach((status) => {
            if (isValidStatus(status)) {
                statusList.push(status as LoanOverviewNeedsListFiltersState['status'][0]);
            }
        });
    } else if (isValidStatus(query as string)) {
        statusList.push(query as LoanOverviewNeedsListFiltersState['status'][0]);
    }

    return statusList;
}

const getInitialAssignees = (query: string | string[]): LoanOverviewNeedsListFiltersState['assigneesIds'] => {
    if (Array.isArray(query) && query.length > 0) {
        return query.filter(iValidUUID);
    } else if (iValidUUID(query as string)) {
        return [query as string];
    }

    return [];
}

const isValidStatus = (status: string): boolean => {
    return ["OPEN", "IN_PROGRESS", "SUBMITTED", "REVIEWING", "REJECTED", "ACCEPTED", "NEEDS_LEGAL_REVIEW", "NEEDS_ATTENTION", "OPEN_FILE_REQUEST"].includes(status);
}

// eg: 2c021042-b4e1-4164-b2c1-03735812c600
const iValidUUID = (uuid: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(uuid);
}